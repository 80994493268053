import { Button } from '@mui/material';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import { useContext } from 'react';
import { ArteFinalContext_v2 } from '../../context/ArteFinalContext_v2';
import { useProviderShoppingCart } from '../../hooks/useProviderFormat';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

const FooterCard = () => {
  const { shoppingCart_v2 } = useContext(ShoppingCartContext);
  const {
    formatSelected,
    quantitySelected,
    formatProviderSelected,
    materialSelected,
    setOpenModalSelectChildrenFormat,
    setRequiredFormatChildren,
    arteFinal,
  } = useContext(ArteFinalContext_v2);
  const { handleAddProviderFormat, handleDecrement, handleIncrement } = useProviderShoppingCart();

  const handleAdd = () => {
    handleAddProviderFormat(setRequiredFormatChildren, setOpenModalSelectChildrenFormat);
  };

  return (
    <div
      className={`${
        !formatSelected.formatoCustom &&
        !shoppingCart_v2[arteFinal._id]?.formatos[formatSelected.formatoId] &&
        !formatProviderSelected
          ? 'hidden'
          : ''
      } `}
    >
      <div
        style={{
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'space-between',
          margin: '1.5rem',
          fontSize: '24px',
        }}
      >
        <CgMathMinus className="icons-style" onClick={handleDecrement} />
        <h2 className="us-none">{quantitySelected}</h2>

        <CgMathPlus className="icons-style" onClick={handleIncrement} />
      </div>
      <div style={{ height: '50px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '0.75rem' }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleAdd}
            style={{ width: '100%' }}
            disabled={
              quantitySelected === 0 ||
              // ya existe en el carrito
              // si es formatCustom y no hay ningun nombre introducido
              (formatSelected.formatoCustom && (!formatSelected.formatoName || !materialSelected)) ||
              shoppingCart_v2[arteFinal._id]?.formatos[formatSelected?.formatoId]?.quantity > 0
            }
          >
            Añadir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FooterCard;
