import { useContext } from 'react';
import { ArteFinalContext_v2 } from '../context/ArteFinalContext_v2';
import { ShoppingCartContext } from '../context/ShoppingCartContext';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { deleteOneFormatProvider } from '../api/carteleriaApi';
import toast from 'react-hot-toast';
import { getProviderFormatByFormatAndProvider } from '../api/providerFormatApi';

export const useProviderShoppingCart = () => {
  const { shoppingCart_v2, setShoppingCart_v2 } = useContext(ShoppingCartContext);
  const { arteFinal, formatSelected, formatProviderSelected, quantitySelected, setQuantitySelected, materialSelected } =
    useContext(ArteFinalContext_v2);

  const { formatoMaterial, ...restFormatSelected } = formatSelected;

  // Solo hay que añadir al carrito el proveedor con su cantidad seleccionada:
  const handleAddProviderFormat = (setRequiredFormatChildren, setOpenModalSelectChildrenFormat) => {
    // Lo primero es comprobar si es compuesto
    if (formatSelected.childrenFormat.format.length > 0) {
      setRequiredFormatChildren(formatSelected.childrenFormat.required);

      const format = [];

      const { nameArteFinal, codeArteFinal, ...rest } = arteFinal;

      format.push({
        ...formatSelected,
        providerFormat: formatProviderSelected,
        price: formatProviderSelected.price,
        arteFinal: {
          ...rest,
          code: codeArteFinal,
          name: nameArteFinal,
          minQuantity: arteFinal.minQuantity || 1,
        },
        checked: true,
        quantity: quantitySelected >= arteFinal.minQuantity ? quantitySelected : arteFinal.minQuantity || 1,
        minQuantity: arteFinal.minQuantity || 1,
      });

      formatSelected.childrenFormat.format.map((childrenFormat, i) => {
        format.push({
          ...childrenFormat,
          checked: true,
          originalQuantity: childrenFormat.quantity.quantity,
          minQuantity: childrenFormat.arteFinal.minQuantity || 1,
          quantity: !formatSelected.childrenFormat.required
            ? childrenFormat.arteFinal.minQuantity || 1
            : childrenFormat.quantity.quantity * quantitySelected || 1,
        });
      });

      setOpenModalSelectChildrenFormat(format);
      return;
    }

    if ((formatProviderSelected || formatSelected.formatoCustom) && quantitySelected > 0) {
      const newShoppingCart = { ...shoppingCart_v2 };

      // Verificar si arteFinal ya existe en el carrito, si no, inicializarlo
      if (!newShoppingCart[arteFinal._id]) {
        newShoppingCart[arteFinal._id] = {
          id: arteFinal._id,
          name: arteFinal.nameArteFinal,
          code: arteFinal.codeArteFinal,
          imglink: arteFinal.imglink,
          montaje: arteFinal.isMontaje,
          minQuantity: arteFinal.minQuantity || 1,
          formatos: {},
        };
      }

      // Agregar o actualizar el formato dentro de arteFinal
      newShoppingCart[arteFinal._id].formatos = {
        ...newShoppingCart[arteFinal._id].formatos,
        [formatSelected.formatoId]: {
          ...restFormatSelected,
          formatoMaterial: materialSelected ? materialSelected.nombre : formatSelected.formatoMaterial,
          material: materialSelected,
          quantity: quantitySelected >= arteFinal.minQuantity ? quantitySelected : arteFinal.minQuantity,
          // Comprobar que el providerFormatSelected corresponda al formato seleccionado:
          providerFormat: formatProviderSelected.format === formatSelected.formatoId ? formatProviderSelected : null,
          price: formatProviderSelected.format === formatSelected.formatoId ? formatProviderSelected.price : null,
        },
      };

      setShoppingCart_v2(newShoppingCart);
    }
  };

  const handleDecrement = () => {
    const aaff = shoppingCart_v2[arteFinal._id];
    const formato = aaff?.formatos?.[formatSelected.formatoId];

    if (formato) {
      if (formato.quantity === 1) {
        // Crear una copia del carrito de compras actual
        const newShoppingCart = { ...shoppingCart_v2 };
        // Eliminar el proveedor del carrito
        delete newShoppingCart[arteFinal._id].formatos[formatSelected.formatoId];
        // Si no hay más formatos, eliminar el arte final
        if (Object.keys(newShoppingCart[arteFinal._id].formatos).length === 0) {
          delete newShoppingCart[arteFinal._id];
        }
        // Actualizar el estado del carrito de compras
        setShoppingCart_v2(newShoppingCart);
        // Restablecer la cantidad seleccionada a 0
        setQuantitySelected(0);
      } else if (formato.quantity > 1) {
        // Crear una copia del carrito de compras actual
        const newShoppingCart = { ...shoppingCart_v2 };
        // Reducir la cantidad del proveedor en el carrito
        newShoppingCart[arteFinal._id].formatos[formatSelected.formatoId].quantity -= 1;
        // Actualizar el estado del carrito de compras
        setShoppingCart_v2(newShoppingCart);
        // Reducir la cantidad seleccionada en el contexto
        setQuantitySelected(quantitySelected - 1);
      }
    } else if (quantitySelected > 0) {
      // Reducir la cantidad seleccionada en el contexto si es mayor que 0
      setQuantitySelected(quantitySelected - 1);
    }
  };

  const handleIncrement = () => {
    // Aumentar la cantidad seleccionada en el contexto
    setQuantitySelected(quantitySelected + 1);

    // Crear una copia del carrito de compras actual
    const newShoppingCart = { ...shoppingCart_v2 };

    // Verificar si el proveedor ya está en el carrito
    if (newShoppingCart[arteFinal._id]?.formatos[formatSelected.formatoId]) {
      // Si el proveedor ya está en el carrito, actualizar la cantidad
      newShoppingCart[arteFinal._id].formatos[formatSelected.formatoId].quantity += 1;
      setShoppingCart_v2(newShoppingCart);
    }
  };

  return { handleAddProviderFormat, handleDecrement, handleIncrement };
};

export const useDeleteProviderFormat = (idFormatProvider, idArte) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => {
      let token = await getAccessTokenSilently();
      await deleteOneFormatProvider(token, idFormatProvider);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['formats-arte-final', idArte]);
      toast.success('Formato eliminado');
    },
    onError: (error) => {
      toast.error(error.message, {
        id: 'delete-provider-format-not-found',
      });
    },
  });
  return { mutation };
};

export const useProviderFormatByFormatAndProvider = (formatId, providerId, index) => {
  return useQuery({
    queryKey: ['providerFormat', formatId, providerId],
    queryFn: () => getProviderFormatByFormatAndProvider(formatId, providerId),
    suspense: true,
    enabled: !!index !== 0,
  });
};
